<div *ngIf="firstLoad" class="first-load" [class.stopping]="stopping">
	<mat-spinner></mat-spinner>
</div>
<div
	*ngIf="!firstLoad"
	class="loading"
	[class.show]="loading() || savingFunctionalEntity() || errorFunctionalEntity()"
	tourAnchor="loading"
>
	<mat-spinner
		*ngIf="!savingFunctionalEntity() && !errorFunctionalEntity()"
		color="primary"
		style="height: 100%; width: 100%"
	></mat-spinner>
	<mat-icon class="mat-primary infinite-fade" *ngIf="savingFunctionalEntity()">save</mat-icon>
	<mat-icon class="mat-warn infinite-fade" *ngIf="errorFunctionalEntity()">wifi_off</mat-icon>
</div>
<ng-container *ngIf="savingDeclarationEntityFromService || savingDeclarationEntityFromState">
	<div id="declaration-saving-block">
		<div id="opacity"></div>
		<div id="spinner" class="w-100 h-100">
			<div class="d-flex-col" style="justify-content: center">
				<mat-spinner></mat-spinner>
			</div>
		</div>
	</div>
</ng-container>
